<div class="layout-footer overflow-x-auto">
    <div class="footer-start">
        <img
            class="mr-8"
            [src]="'assets/layout/images/logo-secondary-white.svg'"
            alt="logo-second"
        />
        <!-- <span class="app-name"
            ></span
        > -->
        <p class="ml-6 mt-2 md-mt-2 lg:mt-5 xl:mt-3 line-height-1 text-sm">
            Dispute Resolution ai (DRai) is a neutral dispute resolution
            organization. DRai is not a law firm and is unable to provide legal
            advice to anyone. Our website and platform provides general
            information about Dispute Resolution ai LLC, its services and its
            professional staff. The information provided here and elsewhere
            within the website, the platform and any communication DRai may have
            with you is not intended to give you any legal advice in regard to
            any legal matter you may have. In any legal matter, including any
            dispute referred to DRai for dispute resolution services, you have
            the right to retain an attorney of your choosing at your own
            expense. DRai encourages each party to any dispute resolved through
            the DRai platform to consider consulting an attorney regarding his,
            her or its rights.
        </p>
    </div>
    <!--  <div class="footer-end">
        <span>© Your Organization</span>
    </div> -->
</div>
