import { inject } from '@angular/core';
import { TypesMenuIcon } from '@core/_enums/types-sidemenu.enum';
import { UserStoreService } from '@core/services/store/user-store.service';
import { MenuItem } from 'primeng/api';

export class SalesRepresentativeRoutes {
    private userStoreService = inject(UserStoreService);

    Routes: MenuItem[] = [
        {
            id: TypesMenuIcon.Custom,
            label: 'Accounts',
            icon: 'assets/icons/sidemenu/users.svg',
            routerLink: '/sales-representative/my-accounts',
        },
        {
            separator: true,
            visible: false,
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'Send Registration form',
            icon: 'assets/icons/sidemenu/send-email-registration.svg',
            routerLink: '/sales-representative/send-registration-form',
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'Register New Account',
            icon: 'assets/icons/sidemenu/users-register.svg',
            routerLink: '/sales-representative/register-new-account',
        },
        {
            separator: true,
            visible: false,
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'Settings',
            icon: 'assets/icons/sidemenu/settings.svg',
            routerLink: '/settings',
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'Logout',
            icon: 'assets/icons/sidemenu/logout.svg',
            command: () => {
                console.log(
                    '🚀 ~ file: sales-representative.routes.ts:39 ~ command Logout:'
                );
                this.userStoreService.logout();
            },
        },
    ];

    RoutesSettings: MenuItem[] = [
        {
            id: TypesMenuIcon.Custom,
            label: 'SETTINGS',
            routerLink: 'user-info',
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'SECURITY',
            routerLink: 'account-security',
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'ACCOUNT PROFILE',
            routerLink: 'account-profile',
        },
        {
            id: TypesMenuIcon.Custom,
            label: 'ACCOUNT TIMEZONE',
            routerLink: 'account-timezone',
        },
    ];
}
